.App {
  text-align: center;
}

.App-header {
  padding-top: 80px;
  background-color: #282c34;
  min-height: 30vh;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.titlebar {
  margin-bottom: 20px;
  background-color: #0d64ef;
  font-family: "Calibri";
  justify-content: center;
  text-align: center;
  width: 100%;
}

.titlebar .navigation{
  width: 100%;
}

.titlebar .menubar{
  width: 33%;
}

.titlebar .logo{
  background-image: url("./Images/tc-energy-en.png");
  background-size: 158px 35px;
  background-repeat: no-repeat;
  background-position: left;
  width: 200px;
}

.titlebar .menu{
  text-align: left;
  width: 80px;
}

.titlebar .title{
  width: 34%;
  text-align: center;
  vertical-align: middle;
  font-size: xx-large;
  font-weight: bold;
  color:white;
}

.titlebar .user{
  width: 33%;
  text-align: right;
  vertical-align: middle;
  font-family: "Calibri";
  font-size: 18px;
  color:white;
  padding-right: 10px;
}

.titlebar .usericon{
  vertical-align: bottom;
  padding-right: 5px;
}

.dx-menu-base .dx-icon {
  width: 40px;
  height: 40px;
  background-position: 0 0;
  background-size: 40px 40px;
  padding: 0;
  font-size: 40px;
  text-align: center;
  line-height: 40px;
}

.menu .dx-menu-base .dx-menu-item .dx-menu-item-content {
  color: white;
  background-color: #0d64ef;
  font-family: "Calibri";
}

.dx-submenu .dx-menu-items-container .dx-menu-item {
  color: white;
  background-color: #0d64ef;
  font-family: "Calibri";
  font-size: 18px;
}

/* .dx-submenu .dx-menu-items-container .dx-icon {
  width: 30px;
  height: 30px;
  background-position: 0 0;
  background-size: 30px 30px;
  padding: 0;
  font-size: 30px;
  text-align: center;
  line-height: 30px;
} */

.dx-popup-content {
  padding-top: 5px;
  padding-bottom: 5px;
}

.dx-overlay-content dx-toast-error dx-toast-content {
  z-index: 999999;
}
