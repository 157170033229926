
.content{
  text-align: justify;
  margin-top: 10px;
}

.dx-tab {
  background-color: white;
  font-size: medium;
  border: 1px solid #ddd;
}

.dx-tab.dx-tab-selected {
  font-weight: bold;
  font-size: medium;
  background-color: #002366;
  cursor:not-allowed;
  color: white;
}
