.filter-container {
  background-color: rgba(191, 191, 191, 0.15);
  padding: 5px;
  width: 100%;
  margin-bottom: 25px;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dx-filterbuilder {
  padding: 10px;
}

.dx-filterbuilder .dx-numberbox {
  width: 80px;
}

.dx-filterbuilder .dx-textbox {
  width: 300px;
}

.parent {
  white-space: nowrap;
  margin: 10px;
  padding-right: 10px;
  width: 100%;
  text-align: right;
}

.child {
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 20px;
  display: inline-block;
}
