.toolbar-label,
.toolbar-label > b {
  font-size: 19px;
}

.dx-button.favorites {
  background-color: #f05b41;
  color: #fff;
  top: 5px;
  right: 0;
}

.dx-button.favorites .dx-icon {
  color: #fff;
}

