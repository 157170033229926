.a-popup-editor {
  width: 100%;
  height: 90%;
  vertical-align: middle;
  overflow-x: hidden;
  overflow-y: auto;
}

.a-popup-buttons {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
