html,
body {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.loginpagemsg {
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 25%; 
}

.loginpage {
  font-family: praxis-next,Arial,Verdana,sans-serif;
  height: 100vh;
}

.loginpagelogo {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  top: 10%;  
}

.loginbutton {
  background-color: #4CAF50;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  top: 30%;  
  cursor: pointer;
}

.login-spinner {
  position: relative;
  margin-left: 45%;
  width: 45%;
  top: 30%; 
}