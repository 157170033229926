.caption {
  font-size: 18px;
  font-weight: 500;
}

.d-popup-details {
  overflow: hidden;
  position: relative;
}
.d-popup-options {
  padding: 10px;
  background-color: rgba(191, 191, 191, 0.15);
}
.d-popup-option {
  margin-top: 5px;
}
.d-popup-buttons {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.addFile-option {
  margin-top: 5px;
  height: 380px;
}
.addFile-property-buttons {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.addFile-fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 10px 5px 10px 5px;
}

#progress-bar-status {
  display: inline-block;
}
.complete .dx-progressbar-range {
  background-color: green;
}
.dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding-top: 0px;
}
.disclaimer {
  color:blue
}



