.dx-fieldset {
  margin-top: 0px;
  margin-bottom: 0px;
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.cf-popup-option {
  margin-top: 5px;
}
.cf-popup-details {
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 350px;
}
.cf-popup-fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 10px 0px 10px 0px;
  width: 520px;
}
.cf-popup-buttons {
  display: flex;
  justify-content: center;
}

.dx-fileuploader-file-name{
  width: 500px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}



