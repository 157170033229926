.home-container {
  width: 100%;
  color: black;
  font-family: "Calibri";
  vertical-align: middle;
}

.home-container .header {
  color: #0d64ef;
  font-family: "Calibri";
  font-weight: bold;
  text-align: center;
}

.home-container .title {
  text-align: left;
  color: black;
  font-family: "Calibri";
  /* font-weight: bold; */
  margin-top: 0px;
  margin-bottom: 5px;
}

.dx-toolbar {
  margin-bottom: 5px;
}

.dx-htmleditor-content {
  color: black;
  font-family: "Calibri";
}