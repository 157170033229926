.dx-fieldset {
  margin-top: 0px;
  margin-bottom: 0px;
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.vr-submit-popup-details {
  overflow: hidden;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 630px;
}
.vr-submit-popup-option {
  margin-top: 5px;
}
.vr-submit-popup-buttons {
  display: flex;
  justify-content: center;
}


