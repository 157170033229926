.home-container {
  width: 100%;
  color: black;
  font-family: "Calibri";
  vertical-align: middle;
  margin-bottom: 20px;
}

.home-container .header {
  color: #0d64ef;
  font-family: "Calibri";
  font-weight: bold;
  text-align: center;
}

.home-container .title {
  text-align: left;
  color: black;
  font-family: "Calibri";
  /* font-weight: bold; */
  margin-top: 0px;
  margin-bottom: 5px;
}

.home-container .announcement {
  width: 75%;
  vertical-align: top;
  float:left;
  border: 1px solid;
  padding: 3px;
  /* overflow: hidden; */
}

.home-container .reference {
  width: 23%;
  vertical-align: top;
  float: right;
  border: 0.1px solid;
  padding: 3px;
  /* overflow: hidden; */
}

.home-container .dx-list-item-content {
  white-space: normal;
  font-family: "Calibri";
  font-size: 18px;
}

.dx-toolbar {
  margin-bottom: 5px;
}

.dx-htmleditor-content {
  color: black;
  font-family: "Calibri";
}

